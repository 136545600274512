<template>
  <div>
    <div class="subHeader base noFlex">
      <div class="module-header">
        <h2>회원정보</h2>
      </div>
      <div class="module-config">
        <div class="wtBox config-list">
          <div class="box form">
            <div class="flex">
              <p>권한</p>
              <label>
                <input
                  type="radio"
                  name="Role"
                  value="1002"
                  v-model="userType"
                />관리자
              </label>
              <label>
                <input
                  type="radio"
                  name="Role"
                  value="1001"
                  v-model="userType"
                />일반
              </label>
            </div>
            <div class="flex">
              <p>이름</p>
              <input type="text" v-model="username" />
            </div>
            <div class="flex">
              <p>이메일</p>
              <input type="text" v-model="email" disabled />
            </div>
            <div class="flex">
              <p>전화번호</p>
              <input type="text" v-model="mobileNo" />
            </div>
            <div class="flex">
              <p>포인트</p>
              <input type="text" v-model="point" />
            </div>
            <div class="flex">
              <p>가입일</p>
              <span>{{
                moment(created_at).format("YYYY년 MM월 DD일 HH:mm")
              }}</span>
            </div>
            <div class="flex">
              <p>업데이트일</p>
              <span>{{
                moment(update_at).format("YYYY년 MM월 DD일 HH:mm")
              }}</span>
            </div>
          </div>
          <div class="buttonWrap">
            <router-link to="/admin/users"
              ><button class="right line">목록</button></router-link
            >
            <button class="pointOut" @click="submit">수정</button>
          </div>
        </div>
        <div class="wtBox config-list">
          <el-tabs v-model="activeName">
            <el-tab-pane label="결제 내역" name="credit">
              <div class="box one">
                <table class="module-table lineRow">
                  <colgroup>
                    <col style="width: 5%" />
                    <col style="width: 25%" />
                    <col style="width: 10%" />
                    <col style="width: 12%" />
                    <col style="width: 10%" />
                    <col style="width: 20%" />
                    <col style="width: 10%" />
                    <col style="width: 8%" />
                  </colgroup>
                  <tr>
                    <th>번호</th>
                    <th>상품주문 번호</th>
                    <th>결제일</th>
                    <th>결제금액</th>
                    <th>충전포인트</th>
                    <th>주문명</th>
                    <th>카드명</th>
                    <th>상태</th>
                  </tr>
                  <tr v-for="(item, index) in buyInfo" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <router-link
                        class="primary"
                        :to="{
                          name: 'adminPaymentDetail',
                          params: { checkoutId: item._id },
                        }"
                      >
                        {{ item.merchant_uid }}
                      </router-link>
                    </td>
                    <td>{{ moment(item.created_at).format("YYYY.MM.DD") }}</td>
                    <td>{{ comma(item.paid_amount) }} 원</td>
                    <td>{{ item.total_point }} P</td>
                    <td>{{ item.serviceType }}</td>
                    <td>
                      {{ item.card_name }}
                    </td>
                    <td
                      :class="{
                        success: item.status == 'paid',
                        error: item.status == 'paid_fail',
                        primary: item.status == 'refund',
                        orange: item.status == 'cancelled',
                        error: item.status == 'norefund',
                      }"
                    >
                      {{
                        item.status == "paid"
                          ? "결제 완료"
                          : item.status == "paid_fail"
                          ? "결제 실패"
                          : item.status == "refund"
                          ? "환불 신청"
                          : item.status == "cancelled"
                          ? "환불 완료"
                          : item.status == "norefund"
                          ? "환불 거절"
                          : ""
                      }}
                    </td>
                  </tr>
                </table>

                <div class="pagination">
                  <el-pagination
                    layout="prev, pager, next"
                    :total="totalPage"
                    :page-size="10"
                    @current-change="handleCurrentChange_payment"
                  ></el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="포인트 내역" name="point">
              <div class="box one">
                <table class="module-table lineRow">
                  <colgroup>
                    <col style="width: 10%" />
                    <col style="width: 30%" />
                    <col style="width: 30%" />
                    <col style="width: 30%" />
                  </colgroup>
                  <tr>
                    <th>번호</th>
                    <th>구분</th>
                    <th>날짜</th>
                    <th>포인트</th>
                  </tr>
                  <tr v-for="(data, i) in pointInfo" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td
                      :class="[data.mode == 'deduction' ? 'orange' : 'primary']"
                    >
                      {{
                        data.mode == "charge"
                          ? "적립"
                          : data.mode == "deduction"
                          ? "차감"
                          : ""
                      }}
                    </td>
                    <td>
                      {{ moment(data.createdAt).format("YYYY.MM.DD") }}
                    </td>
                    <td>
                      {{ data.point + data.plus_point }}
                    </td>
                  </tr>
                </table>
                <div class="pagination">
                  <el-pagination
                    layout="prev, pager, next"
                    :total="totalPage_point"
                    :page-size="10"
                    @current-change="handleCurrentChange_payment"
                  ></el-pagination>
                </div>
              </div>
            </el-tab-pane>

            <el-tab-pane label="문의사항 내역" name="qna">
              <!-- <NoData v-if="qnaList.length == 0" /> -->
              <div class="box one">
                <table class="module-table lineRow">
                  <colgroup>
                    <col style="width: 5%" />
                    <col style="width: 15%" />
                    <col style="width: 50%" />
                    <col style="width: 15%" />
                    <col style="width: 15%" />
                  </colgroup>
                  <tr>
                    <th>번호</th>
                    <th>문의 유형</th>
                    <th>문의 제목</th>
                    <th>날짜</th>
                    <th>상태</th>
                  </tr>
                  <tr v-for="(data, i) in qnaInfo" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ data.category }}</td>
                    <td>
                      <router-link
                        :to="`/admin/qnaManagement?id=${data._id}`"
                        >{{ data.title }}</router-link
                      >
                    </td>
                    <td>
                      {{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}
                    </td>
                    <td :class="[data.status ? 'orange' : 'primary']">
                      {{ data.status ? "답변완료" : "답변대기" }}
                    </td>
                  </tr>
                </table>

                <div class="pagination">
                  <el-pagination
                    layout="prev, pager, next"
                    :total="totalPage_qna"
                    :page-size="10"
                    @current-change="handleCurrentChange_qna"
                  ></el-pagination>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
import moment from "moment";
import { fetchUserDetail, updateUserInfo } from "@/api/member";
import { format } from "@/mixins/format";
import { fetchUserPayments, fetchUserQna, fetchUserPoints } from "@/api/admin";

export default {
  mixins: [format],
  name: "UserManage",
  data() {
    return {
      moment: moment,
      userType: "",
      totalPage: 0,
      currentPage: 1,
      userId: "",
      isAdmin: false,
      username: "",
      activeName: "credit",
      email: "",
      mobileNo: "",
      created_at: "",
      update_at: "",
      isPaid: false,
      point: 0,
      buyInfo: [],
      qnaInfo: [],
      pointInfo: [],
      totalPage_qna: 0,
      currentPage_qna: 1,
      totalPage_point: 0,
      currentPage_point: 1,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
  },
  mounted() {
    this.userId = this.$route.query.id;
    this.getUserDetail();
    this.getUserPayments();
    this.getUserQna();
    this.getUserPoints();
  },
  methods: {
    getUserDetail() {
      fetchUserDetail(this.userId).then((res) => {
        if (res.data.status == 200) {
          if (res.data.status == 409) {
            this.$router.push("/");
            return alert(res.data.message);
          }
          this.isAdmin = res.data.data.isAdmin;
          this.userType = res.data.data.userType;
          this.username = res.data.data.username;
          this.email = res.data.data.email;
          this.mobileNo = res.data.data.mobileNo;
          this.created_at = res.data.data.created_at;
          this.update_at = res.data.data.update_at;
          this.point = res.data.data.point;
          this.isPaid = res.data.data.isPaid;
        } else {
          alert(res.data.message);
        }
      });
    },
    submit() {
      let data = {
        username: this.username,
        email: this.email,
        mobileNo: this.mobileNo,
        userId: this.userId,
        userType: this.userType,
        point: this.point,
      };
      updateUserInfo(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "회원 정보가 수정 되었습니다.",
            type: "success",
          });
        } else {
          return alert(res.data.message);
        }
      });
    },
    getUserPayments() {
      let params = {
        page: this.currentPage,
        userId: this.userId,
      };
      fetchUserPayments(params).then((res) => {
        this.buyInfo = res.data.data;
        this.currentPage = res.data.currentPage;
        this.totalPage = res.data.totalPage;
      });
    },
    handleCurrentChange_payment(val) {
      this.currentPage = val;
      this.getUserPayments();
    },
    getUserQna() {
      let params = {
        page: this.currentPage_qna,
        userId: this.userId,
      };
      fetchUserQna(params).then((res) => {
        this.qnaInfo = res.data.data;
        this.currentPage_qna = res.data.currentPage;
        this.totalPage_qna = res.data.totalPage;
      });
    },
    handleCurrentChange_qna(val) {
      this.currentPage_qna = val;
      this.getUserQna();
    },

    getUserPoints() {
      let params = {
        page: this.currentPage_point,
        userId: this.userId,
      };
      fetchUserPoints(params).then((res) => {
        this.pointInfo = res.data.data;
        this.currentPage_point = res.data.currentPage;
        this.totalPage_point = res.data.totalPage;
      });
    },
    handleCurrentChange_point(val) {
      this.currentPage_point = val;
      this.getUserPoints();
    },
  },
};
</script>
