import axios from "axios";
import { baseUrl, config, headers } from "../config/env";

export function fetchPostList(params) {
    return axios.get(baseUrl + `/admin/post/list`, {params, headers});
}

export function fetchPostDashboard() {
    return axios.get(baseUrl + `/admin/post/dashboard`, config);
}

export function fetchUserPayments(params) {
    return axios.get(baseUrl + `/admin/userInfo/payments/${params.userId}`, {params, headers})
}

export function fetchUserQna(params) {
    return axios.get(baseUrl + `/admin/userInfo/qna/${params.userId}`, {params, headers})
}
export function fetchUserPoints(params) {
    return axios.get(baseUrl + `/admin/userInfo/points/${params.userId}`, {params, headers})
}